import { Power1, Power2 } from 'gsap';

export function animateLine(tl, el) {
  return (
    tl
      // .from(el, 0.3, { autoAlpha: 1, display: 'none' })
      // .to(el, 0.9, { autoAlpha: 1, display: 'block' });
      // .to(el, 0, { autoAlpha: 0.09, repeat: 1, yoyo: true, ease: Power1.easeNone }, 0);
      // .set(el, { autoAlpha: 1, display: 'none' })
      .from(el, 0.3, { autoAlpha: 0.09, display: 'none' })
      .to(el, 0.3, {
        ease: Power1.easeOut,
        autoAlpha: 0.09,
        display: 'block',
      })
  );
}

export function animateHeading(tl, element1, element2) {
  return tl
    .staggerFrom(
      element1,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      element2,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    );
}

export function animateImage(tl, svgLine, svgCircle, image, container) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      '#desc_one',
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      '#desc_two',
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: 120,
        y: 30,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '-70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateImageRight(
  tl,
  svgLine,
  svgCircle,
  image,
  container,
  title,
  description,
  button
) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      0.7,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      0.7
    )
    .staggerFrom(
      description,
      1.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.9
    )
    .staggerFrom(
      button,
      1.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.9
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateImageLeftBox(tl, title, svgLine, svgCicle, description, image, container) {
  const o = 0.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCicle,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(description, 2.5, {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    })
    .staggerFrom(
      container,
      1.3,
      {
        x: 120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '-70px',
        y: '70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}
export function animateImageLeft(
  tl,
  svgLine,
  svgCicle,
  image,
  container,
  title,
  description,
  button
) {
  const o = 0.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCicle,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      1.1,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.1
    )
    .staggerFrom(
      button,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: 120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '-70px',
        y: '70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateImageXRight(
  tl,
  svgLine,
  svgCircle,
  image,
  container,
  title,
  description,
  button,
  X,
  Y
) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      button,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      1.3,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateImageXLeft(
  tl,
  svgLine,
  svgCircle,
  image,
  container,
  title,
  description,
  button,
  X,
  Y
) {
  const o = 1.3;
  tl.set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 15,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      button,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      o,
      {
        x: 120,
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      o,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function AnimateEaseInOut(tl, element) {
  return tl.staggerFrom(
    element,
    0.5,

    {
      opacity: 0,
      y: 50,
      ease: Power1.easeOut,
    },
    0,
    1.4
  );
}

export function fadeUp(tl, el) {
  return tl.staggerFrom(
    el,
    0.5,
    {
      opacity: 0,
      y: 50,
      ease: Power1.easeOut,
    },
    1,
    0.5
  );
}

export function fadeUpHome(tl, el) {
  return tl.staggerFromTo(
    el,
    0.5,
    {
      opacity: 0,
      y: 50,
      ease: Power1.easeOut,
    },
    {
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    },
    1,
    0.5
  );
}

export function opacityZero(tl, el) {
  return tl.staggerTo(el, 0.3, {
    opacity: 0,
    // y: 50,
    ease: Power1.easeOut,
  });
}

export function fadeUpGrow(tl, el) {
  return tl.staggerFrom(
    el,
    1.3,

    {
      opacity: 0,
      y: 50,
      ease: Power1.easeOut,
    },
    0,
    1.3
  );
}

export function fadeLeft(tl, el) {
  return tl.staggerFrom(
    el,
    0.5,

    {
      opacity: 0,
      x: -150,
      ease: Power1.easeOut,
    },
    0,
    0.5
  );
}

export function fadeRight(tl, el) {
  return tl.staggerFrom(
    el,
    0.5,

    {
      opacity: 0,
      x: 150,
      ease: Power1.easeOut,
    },
    0,
    0.5
  );
}

export function fadeDown(tl, el) {
  return tl.staggerFrom(
    el,
    0.5,

    {
      opacity: 0,
      y: -50,
      ease: Power1.easeOut,
    },
    0,
    1.4
  );
}

export function animateLineToLeft(tl, svgLine, svgCircle) {
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
    .to(
      '.line-horizontal',
      0.5,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      1
    );
}

export function animateImageToLeft(tl, image, bg) {
  return tl
    .staggerFrom(
      bg,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      image,
      1.3,
      {
        x: '50px',
        y: '-50px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function ScaleIcons(tl, icon, title, description) {
  const o = 1;
  return tl
    .staggerFrom(
      icon,
      0.5,
      {
        opacity: 0,
        scale: 0.9,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      title,
      0.5,
      {
        opecity: 0,
        y: 20,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      0.5,
      {
        opecity: 0,
        y: 20,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateBG(tl, element, X) {
  const o = 0.5;
  return tl.staggerFrom(
    element,
    1,
    {
      x: X,
      y: 0,
      opacity: 0,
      ease: Power2.easeOut,
    },
    0,
    o
  );
}

export function animateProjectTile(tl, wrapper) {
  return tl.staggerFrom(
    wrapper,
    0.4,
    {
      scale: 0,
      ease: Power1.easeOut,
    },
    0.2
  );
}

export function animateOnlyImageRight(tl, img, bg) {
  return tl
    .staggerFrom(
      bg,
      1.3,
      {
        x: 120,
        y: 30,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      img,
      1.3,
      {
        x: '-70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateOnlyImageLeft(tl, img, bg) {
  return tl
    .staggerFrom(
      bg,
      1.3,
      {
        x: -120,
        y: -30,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerTo(
      img,
      1.3,
      {
        x: '70px',
        y: '70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateTLD(tl, title, svgLine, svgCircle, desc) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to(
      '.line-horizontal',
      0.5,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      1
    );
}

export function animateWhoWeAreSection(tl, title, svgLine, svgCircle, desc) {
  const o = 0.7;
  return tl
    .staggerFromTo(
      title,
      o,
      {
        opacity: 0,
        y: 50,
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to(
      '.line-horizontal',
      0.5,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      1
    );
}

export function animateWhoWeAreDiff(tl, title, svgLine, svgCircle, image, desc) {
  const o = 0.7;
  return tl
    .staggerFromTo(
      title,
      o,
      {
        opacity: 0,
        y: 50,
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      image,
      0.5,

      {
        opacity: 0,
        x: -150,
        ease: Power1.easeOut,
      },
      0
    )
    .staggerFromTo(
      desc,
      0.5,
      {
        opacity: 0,
        y: 50,
        ease: Power1.easeOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power1.easeOut,
      },
      0
    );
}
