import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Observer from 'react-intersection-observer';
import { TimelineLite } from 'gsap';
// to disable scroll when modal is open
// https://github.com/willmcpo/body-scroll-lock
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/exciting-happenings.scss';
import * as globalAnimation from '../animations/globalAnimation';
import ExcitingHappeningsModal from '../components/ExcitingHappenings/ExcitingHappeningsModal';

export default class ExcitingHappenings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.fadeUp(this.tl, '.animate-exciting-cards');
    this.targetElement = document.querySelector('#targetElementId');
  }

  render() {
    const {
      data: {
        allMarkdownRemark: { edges },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Exciting Happenings" />
        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <div className="wrapper">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">
                      Exciting Happenings
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle">
                      Exciting Happenings
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Container>
            <Row className="justify-content-center animate-exciting-cards pb-5">
              {edges.map(card => (
                <ExcitingHappeningsModal card={card} />
              ))}
            </Row>
          </Container>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "exciting_happenings" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            display_img {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
