import React, { PureComponent } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useCookies } from 'react-cookie';
import { Link } from 'gatsby';
import Header from '../Header';
import Footer from '../Footer';
import useSiteMetadata from '../../hooks/useSiteMetaData';

const Layout = ({ children, navbarColor }) => {
  const { title, author, menu, footerMenus } = useSiteMetadata();
  const { name, photo } = author;
  const [cookies] = useCookies(['name']);

  return (
    <div>
      <Header menus={menu} title={title} navbarColor={navbarColor} logo={photo} />
      {children}
      <LayoutClass cookie={cookies.BprgCookie} />
      <Footer authorName={name} footerMenus={footerMenus} />
      <CookieConsent
        location="top"
        cookieName="BprgCookie"
        containerClasses="cookie_bar"
        buttonText="X"
        style={{ background: '#57b7f9', margin: '0px' }}
        buttonStyle={{
          background: 'none',
          color: '#fff',

          fontWeight: 'bold',
          borderRadius: '50px',
        }}
        onAccept={() => {
          window['ga-disable-UA-138584690-1'] = false;
        }}
        expires={365}
      >
        <div className="pr-lg-5 mr-lg-5" style={{ lineHeight: 'normal' }}>
          <p>
            We use cookies and similar technologies on our website to help provide you with the best
            possible online experience. By using our website, you agree that we may store and access
            cookies and similar technologies on your device.
          </p>

          <p>
            You can find out more and set your own preferences{' '}
            <u>
              <Link to="privacy-cookies" target="_blank" className="text-white">
                here.
              </Link>
            </u>
          </p>
        </div>
      </CookieConsent>

      {/* <CookieConsent
        location="bottom"
        cookieName="BprgCookie"
        style={{ background: '#57b7f9', margin: '0px' }}
        buttonStyle={{
          background: '#0a4169',
          color: '#fff',
          fontSize: '13px',
          borderRadius: '50px',
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{' '}
      </CookieConsent> */}
    </div>
  );
};

export default Layout;

class LayoutClass extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // window['ga-disable-UA-138584690-1'] = false;
    const { cookie } = this.props;
    if (cookie === 'flase') {
      window['ga-disable-UA-138584690-1'] = true;
    } else if (cookie === 'true') {
      window['ga-disable-UA-138584690-1'] = false;
    }
  }

  render() {
    return <div />;
  }
}
