import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

export default class ExcitingHappeningsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  handleShowModal = () => {
    if (window.innerWidth > 992) {
      document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
      document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    }
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;
    const {
      card: {
        node: {
          html,
          // eslint-disable-next-line camelcase
          frontmatter: { title, display_img },
        },
      },
    } = this.props;
    return (
      <Col xs={10} lg={6} className="exciting-modal">
        <div className="exciting-happening-brick position-relative mb-4">
          <Img className="img-fluid" fluid={display_img.childImageSharp.fluid} />
          <div onClick={this.handleShowModal} className="description_block align-items-end">
            <Col xs={12} lg={9}>
              <h5 className="text-white title-text text-bold">{title}</h5>
            </Col>
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="exciting-happening-modal myfix"
          centered
          show={showModal}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton className=" p-0 bg-office-modal text-white">
            <div className="h-100 w-100 position-absolute rounded">
              <BackgroundImage
                Tag="section"
                className="modal-cover-image"
                fluid={display_img.childImageSharp.fluid}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <h5 className="text-appcolor">{title}</h5>
            <div className="text-appcolor" dangerouslySetInnerHTML={{ __html: html }} />
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
}
