/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Linkedin from '../../../static/assets/linkedin.svg';
import ReadMore from '../../../static/assets/Readmore-white.svg';
// import WMTLogo from '../../../static/assets/footer/Logo_New.svg';
import './footer.scss';

const Footer = props => {
  const { footerMenus, authorName } = props;
  return (
    <footer className="bg-appcolor-dark text-white">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-lg-between align-items-center px-lg-3 py-4 my-2 flex-column flex-lg-row">
              <div className="d-flex">
                <span className="pr-2 align-self-end">Follow us on</span>
                <a
                  href="https://www.linkedin.com/company/blueprint-research-group/"
                  target="_blank"
                  className="d-inline-block follow-link"
                >
                  <Linkedin className="mb-1" height="18px" width="18px" />
                </a>
              </div>
              <div className="text-center d-lg-flex">
                <p className="px-3 d-flex d-lg-inline-block mb-2 mb-lg-0 align-self-center">
                  Take your next step with BluePrint
                </p>
                <Link
                  to="/apply-now/"
                  className="bg-appcolor-light joinus footer-section d-inline-block align-items-center py-1"
                >
                  Join Us
                  <ReadMore className="arrow-translate" height="10px" width="40px" />
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="px-lg-3">
              <div className="d-flex justify-content-lg-between align-items-center border-top-white-light pb-3 pt-4 text-small flex-column flex-lg-row">
                <ul className="footer-cta">
                  {footerMenus.map((item, index) => (
                    <li className="footer_nav pr-2 pr-lg-4" key={String(index)}>
                      <Link to={item.path}>{item.label}</Link>
                    </li>
                  ))}
                </ul>
                <div className="d-flex flex-row pb-3">
                  {/* <p className="text-white-light">Design & Developed By</p> */}
                  {/* <div style={{ height: '25x' }}>
                    <a href="https://webmobtech.com/" target="_blank" rel="nofollow">
                      <WMTLogo alt="" className="h-100 ml-2 pr-lg-4" />
                    </a>
                  </div> */}
                </div>
                <div>
                  <p className="text-small text-white-light">
                    Copyright © {new Date().getFullYear()} by {authorName}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
