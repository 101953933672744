/* eslint-disable */
import React from 'react';
import { Link } from 'gatsby';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from '../../../static/assets/bprg_logo.svg';
import './header.scss';

const activeStyles = {
  background: 'white',
  color: 'black',
};
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: 'active',
      navbarOpen: false,
      currentPath: '',
      activeClassState: '',
      current: '',
    };
  }

  componentDidMount() {
    this.setState({
      current: window.location.pathname,
    });
  }

  checkActiveLink = () => {};

  handleNavbar = () => {
    const { navbarOpen } = this.state;
    this.setState({
      navbarOpen: !navbarOpen,
    });
  };

  render() {
    const { menus, navbarColor } = this.props;
    let x = {};
    menus.map(i => {
      return i.submenus.map(j => {
        x[j.path] = i.key;
        return j.path;
      });
    });

    const { navbarOpen, current } = this.state;
    return (
      <>
        <div fixed="top" className="banner bg-appcolor-dark text-white">
          <span>
            We are excited to announce that Trinity Life Sciences has acquired BluePrint Research
            Group. Read more in <Link to="/press-release-faq/">our Press Release</Link> and our{' '}
            <Link to="/press-release-faq/">FAQs.</Link>
          </span>
        </div>
        <Navbar
          bg={navbarColor}
          expand="lg"
          fixed="top"
          className={`navbar_basic ${navbarOpen ? 'navbar-open' : ''}`}
        >
          <div className="d-flex d-lg-block justify-content-between mobile-navigation" id="nav_bar">
            <Link className="navbar-brand" to="/">
              <Logo width="140px" height="41px" />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              aria-labelledby="customDropdown"
              onClick={this.handleNavbar}
            />
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="">
            <Nav className="ml-auto">
              {menus.map((item, index) =>
                item.submenus.length === 0 ? (
                  <Link to={item.path} key={index} className={`nav-link`} activeClassName="active">
                    {item.label}
                  </Link>
                ) : (
                  <NavDropdown
                    title={item.label}
                    id="basic-nav-dropdown"
                    key={index}
                    activeKey="active"
                    className={x[current] === item.key ? 'active master' : ''}
                  >
                    <span className="dropdown-menu-arrow" />

                    {item.submenus.map((subitems, subindex) => (
                      <Link
                        to={subitems.path}
                        key={subindex}
                        className={`dropdown-item ${
                          subitems.path === current ? 'parent-active' : 'parent-deactive'
                        }`}
                        activeClassName="active"
                      >
                        {subitems.label}
                      </Link>
                    ))}
                  </NavDropdown>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
